
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "LogReportType",
  props: {
    type: {},
  },
  setup(props) {
    const data = ref({name: '', clazz: ''});

    const build = (status) => {
      switch (status) {
        case 'NEW_CASE_DAILY':
          data.value = {name: 'New Case Daily', clazz: 'badge-info'}
          break
        case 'INPATIENT_DAILY':
          data.value = {name: 'Inpatient Daily', clazz: 'badge-info'}
          break
        case 'CLAIM_PERFORMANCE':
          data.value = {name: 'Claim Performance', clazz: 'badge-info'}
          break
        case 'GENERIC':
          data.value = {name: 'Other', clazz: 'badge-info'}
          break
      }
    }
    build(props.type)

    watch(() => props.type, cb => {
      build(cb);
    })
    return {
      data
    }
  }
})
