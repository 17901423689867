
import {defineComponent, ref, watch} from 'vue'
import * as Yup from "yup";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ClientSelect} from "@/core/composite/composite";
import {Field} from "vee-validate";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import LogReportService from "@/core/services/LogReportService";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";

export default defineComponent({
  name: "LogReportForm",
  components: {DateTimeSelect, BaseRadio, BaseCheckBox, BaseSelect, FormErrorMessage, BaseForm, Field},
  props: {
    id: {type: String},
    report: {type: Object, required: true},
  },
  emits: ['saved'],
  setup(props) {
    const model = ref(props.report);
    watch(() => props.report, cb => {
        model.value = cb;
        if (cb.schedule.period === 'WEEKLY') {
          buildWeekly(cb.schedule.frequencies)
        }
        if (cb.schedule.period === 'MONTHLY') {
          buildMonthly(cb.schedule.frequencies)
        }
      }
    )

    const submitting = ref(false);
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
    });
    const monthly = ref('');
    const weekly = ref({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    })

    const buildWeekly = (frequencies: any[]) => {
      weekly.value = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
      frequencies.forEach(item => {
        if (item == 1) {
          weekly.value.monday = true
        }
        if (item == 2) {
          weekly.value.tuesday = true
        }
        if (item == 3) {
          weekly.value.wednesday = true
        }
        if (item == 4) {
          weekly.value.thursday = true
        }
        if (item == 5) {
          weekly.value.friday = true
        }
        if (item == 6) {
          weekly.value.saturday = true
        }
        if (item == 7) {
          weekly.value.sunday = true
        }
      })
    }
    const buildMonthly = (frequencies: any[]) => {
      monthly.value = frequencies.join(",");
    }
    return {
      model,
      monthly,
      weekly,
      submitting,
      validate,
      ...ClientSelect(true),
    }
  },
  methods: {
    onSave() {
      this.submitting = true;
      if (this.model.schedule.period === 'WEEKLY') {
        this.model.schedule.frequencies = []
        if (this.weekly.monday) {
          this.model.schedule.frequencies.push(1)
        }
        if (this.weekly.tuesday) {
          this.model.schedule.frequencies.push(2)
        }
        if (this.weekly.wednesday) {
          this.model.schedule.frequencies.push(3)
        }
        if (this.weekly.thursday) {
          this.model.schedule.frequencies.push(4)
        }
        if (this.weekly.friday) {
          this.model.schedule.frequencies.push(5)
        }
        if (this.weekly.saturday) {
          this.model.schedule.frequencies.push(6)
        }
        if (this.weekly.sunday) {
          this.model.schedule.frequencies.push(7)
        }
      }
      if (this.model.schedule.period === 'MONTHLY') {
        this.model.schedule.frequencies = []
        this.model.schedule.frequencies = this.monthly.split(",").map(item => Number(item));
        if (this.model.schedule.frequencies[0] === 0) {
          this.model.schedule.frequencies = []
        }
      }
      if (this.id) {
        LogReportService.update(this.id, this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false
        })
      } else {
        LogReportService.create(this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
