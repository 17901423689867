
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "LogReportStatus",
  props: {
    status: {}
  },
  setup(props) {

    const data = ref({name: '', clazz: ''});

    const build = (status) => {
      switch (status) {
        case 'ACTIVE':
          data.value = {name: 'Active', clazz: 'badge-primary'}
          break
        case 'ARCHIVED':
          data.value = {name: 'Archived', clazz: 'badge-info'}
          break
      }
    }
    build(props.status)

    watch(() => props.status, cb => {
      build(cb);
    })

    return {
      data
    }
  }
})
