
import {computed, defineComponent, onMounted, ref} from 'vue'
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {ClientSelect, LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import LogReportForm from "@/views/logReport/LogReportForm.vue";
import LogReportStatus from "@/views/logReport/LogReportStatus.vue";
import LogReportType from "@/views/logReport/LogReportType.vue";

export default defineComponent({
  name: "LogReportSearch",
  components: {
    LogReportType,
    LogReportStatus,
    LogReportForm,
    BaseModal,
    DateTimeFormat,
    KtDatatable,
    QuickAction,
    Entities,
  },
  setup() {


    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Current Report",
        key: "reportDate",
        sortable: true,
      },
      {
        name: "Next Report",
        key: "nextDate",
        sortable: true,
      },
    ]);

    const objectFilter = ref({clientId: '', archived: false});
    const page = computed(() => store.state.LogReportModule.page);
    const state = ref({
      id: '',
      formName: '',
      report: {
        schedule: {period: 'DAILY', frequencies: []},
        name: '',
        clientIdReq: '',
        nextDate: new Date().getTime(),
        type: 'GENERIC'
      }
    })
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Reports',
        [
          {link: false, router: '', text: 'Reports'}
        ]
      )
    })
    return {
      state,
      page,
      objectFilter,
      ...ClientSelect(true),
      tableHeader,
      ...LoadPanel(),
      ...LoadFilterObjects(Actions.LOAD_LOG_REPORTS, objectFilter.value, ['client']),

    }
  },
  methods: {
    onAdd() {
      this.state.formName = 'Add Report'
      this.state.id = ''
      this.state.report = {
        schedule: {period: 'DAILY', frequencies: []},
        name: '',
        clientIdReq: '',
        nextDate: new Date().getTime(),
        type: 'GENERIC'
      }
      const modal = this.$refs.reportFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.reportFormRef as typeof BaseModal;
      modal.hideBaseModal();
      this.paginationLoad();
    },
    onDiscard() {
      this.objectFilter.clientId = '';
      this.paginationLoad()
    },
    onSearch() {
      this.updateFilterObject(this.objectFilter)
    }
  }
})
